import axios from "axios";
import { Toast } from 'vant';
import i18n from '../lang'
import router from '../router'
import store from '../store'


let loadingInstance = null;
let requestNum = 0;
const addLoading = (loadingTxt) => {
    // 增加loading 如果pending请求数量等于1，弹出loading, 防止重复弹出
    requestNum++;
    if (requestNum == 1) {
        loadingInstance = Toast.loading({
            message: loadingTxt,
            forbidClick: true,
        });
    }
};

const cancelLoading = () => {
    // 取消loading 如果pending请求数量等于0，关闭loading
    requestNum--;
    if (requestNum === 0) loadingInstance?.close();
};

export const createAxiosByinterceptors = (
    config
) => {
    const instance = axios.create({
        timeout: 1000,    //超时配置
        withCredentials: true,  //跨域携带cookie
        ...config,   // 自定义配置覆盖基本配置
    });

    // 添加请求拦截器
    instance.interceptors.request.use(
        function (config) {
            // 在发送请求之前做些什么
            const { loading = true, loadingTxt = i18n.tc("request.loadingText") } = config;
            // console.log("config:", config);
            // config.headers.Authorization = store.state.token;
            config.headers['Content-Type'] = 'application/json; charset=utf-8'
            if (config.method === 'post') {
                config.data = {
                    ...config.data,
                    lang: store.state.language,
                    token: store.state.token,
                }
            }
            if (loading) addLoading(loadingTxt);
            return config;
        },
        function (error) {
            // console.log(error,'55555555');
            // 对请求错误做些什么
            return Promise.reject(error);
        }
    );

    // 添加响应拦截器
    instance.interceptors.response.use(
        function (response) {
            // 对响应数据做点什么
            // console.log("response:", response);
            const { loading = true } = response.config;
            if (loading) cancelLoading();
            const { data } = response;
            // 请求成功处理
            if (data.code !== 1 && data.status !== 200) {
                Toast.fail(data.info)
                if (data.code === 401) {
                    router.push('/sign')
                }
            }
            return Promise.resolve(data);
        },
        function (error) {
            // 对响应错误做点什么
            // console.log("error-response:", error.response);
            // console.log("error-config:", error.config);
            // console.log("error-request:", error.request);
            const { loading = true } = error.config;
            if (loading) cancelLoading();
            if (error.response) {
                if (error.response.status === 401) {
                    router.push('/sign')
                }
            }

            Toast.fail(error?.response?.data?.message || error.message || i18n.tc("request.Serverexception"));
            return Promise.reject(error);
        }
    );
    return instance;
};

